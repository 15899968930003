import * as React from 'react';
import { useEffect, useState } from 'react';
import axios from 'axios';

type Doc = {
  title: string;
  url: string;
};

export default ({ street }: { street: string }) => {
  const [docs, setDocs] = useState<ReadonlyArray<Doc>>([]);

  useEffect(() => {
    const fetchData = () => {
      axios
        .get('https://mos-docs-worker.mashintsev.workers.dev/', {
          params: {
            street: street,
          },
        })
        .then((result) => {
          setDocs(result.data.results);
        });
    };

    fetchData();
  }, []);
  return (
    <div>
      {docs.map((doc) => (
        <p>
          <a href={doc.url} target="_blank">
            {doc.title}
          </a>
        </p>
      ))}
    </div>
  );
};
